import { Component, OnInit } from '@angular/core';
import { ParentComponent } from '../parent/parent.component';
import { DBProviderService } from '../services/db-provider.service';
import { RestProviderService } from '../services/rest-provider.service';
import { SharedDataService } from '../services/shared-data.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import hiBase64 from 'hi-base64';
import { Platform} from '@ionic/angular';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Device } from '@ionic-native/device/ngx';
import { EventsService } from '../services/events-service';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
@Component({
  selector: 'app-init',
  templateUrl: './init.page.html',
  styleUrls: ['./init.page.scss'],
})
export class InitPage extends ParentComponent implements OnInit {
  listAvailableSettings:any[]=[];
  indexSetting:any=0;
  constructor(
    private ons:OpenNativeSettings,
    private faio: FingerprintAIO,
    public events: EventsService,
    private router: Router,
    private route: ActivatedRoute,
    private platform: Platform,
    public restProvider: RestProviderService,
    public domSanitizer: DomSanitizer,
    public sharedData: SharedDataService,
    public dbProvider: DBProviderService,
    public device: Device,
    public screenOrientation: ScreenOrientation) {
    super(restProvider,events,domSanitizer, sharedData,device,screenOrientation)
  }

  ngOnInit() {
    this.platform.ready().then(() => {
      let sessionKey = this.route.snapshot.queryParamMap.get('k');
      if (sessionKey) {
        sessionKey = hiBase64.decode(sessionKey);
        this.sharedData.sessionKey = sessionKey;
        this.restProvider.executeHttpMethod('BW2Mobile_ValidateRedirectParams', { sessionKey: sessionKey })
          .subscribe(result => {
            let data = JSON.parse(result);
            this.sharedData.deviceGuid = data.deviceGuid;
            this.sharedData.portalGuid = data.portalGuid;
            this.sharedData.sessionKey = data.sessionKey;
            this.sharedData.appURL = data.appURL;
            this.getAppResources();
            this.redirectToMainPage();
          }, error => {
            console.error(error);
            this.showMessage('Error', JSON.stringify(error));
          });
      } else {
        if (this.isMobile()) {
          this.executeRedirectMenuOrLogin();
        } else {
          this.router.navigate(['/lpm/lp'], { skipLocationChange: true });
        }
      }
    });
  }

  private executeRedirectMenuOrLogin(){
    this.dbProvider.init().then(result => {
      setTimeout(() => {
        this.dbProvider.selectAllFromTable('mobile_settings').then(result => {
          if(result && result.length>0)
          {
            let authenticationType=result[0]['authentication_type'];
            if(authenticationType=='biometric')
            {
              this.sharedData.biometricAccessEnabled=true;
            }
            this.redirectMenuOrLogin();
          }else{
            this.dbProvider.insertMobileSettings().then(result => {     
              this.redirectMenuOrLogin();
             }, error => {
              console.error(error);
            });
          }
        }, error => {
          console.error(error);
        });
      }, 250);
    }, error => {
      console.error(error);
    });
  }

  private retryGetSessionKeyDevice(){
    let setting:any=this.listAvailableSettings[this.indexSetting];
    return this.executeGetSessionKeyDevice(setting).then(result => {
      this.redirectToMainPageMobile();
    }, error => {
      console.error(error);
      if(this.listAvailableSettings.indexOf(setting)<this.listAvailableSettings.length-1)
      {
        this.indexSetting=this.indexSetting+1;
        this.retryGetSessionKeyDevice();
      }else{
        this.indexSetting=0;
        this.retryGetSessionKeyDevice();
      }
    });
  }

  private redirectToMainPageMobile(){
    this.dbProvider.updateAllSettings().then(res => {
      this.dbProvider.insertOrUpdateSetting(this.sharedData.deviceGuid,this.sharedData.portalGuid).then(res => {
        this.redirectToMainPage();
      }).catch(ex => {
        console.error(ex);
      });
    }).catch(ex => {
      console.error(ex);
    });
  }

  private searchAndUseGetSessionKeyDevice(lastSetting:any){
    this.executeGetSessionKeyDevice(lastSetting).then(result => {
        this.redirectToMainPageMobile();
    }, error => {
      console.error(error);
      this.retryGetSessionKeyDevice();
    });
  }

  public validateBiometricAuthenticationAccess(lastSetting:any){
    this.executeBiometricAuthentication(this.faio,this.platform).then(res => {
      this.searchAndUseGetSessionKeyDevice(lastSetting);
    }, error => {    
      console.error(error);
    });
  }

  public redirectMenuOrLogin(): void {
    this.dbProvider.selectAllFromTable('bw2_settings')
      .then(res => {
        this.listAvailableSettings = res;
        if (this.listAvailableSettings && this.listAvailableSettings.length > 0) {
          let lastSetting = this.listAvailableSettings.find(item => item['last_portal'] == "1");
          if (lastSetting == undefined)
            lastSetting = this.listAvailableSettings[0];
          if (lastSetting && lastSetting['translations']) {
            this.sharedData.translations = JSON.parse(lastSetting['translations']);
          }
          this.verifyIfEnterApp(this.dbProvider,this.faio,this.platform).then(res => {
            this.searchAndUseGetSessionKeyDevice(lastSetting);
          }, error => {    
            console.error(error);
          });
        } else {
          this.router.navigate(['/lpm/lp'], { skipLocationChange: true });
        }
      }, error => {
        console.error(error);
      }).catch(ex => {
        console.error(ex);
        this.showMessage('Error', ex);
      });
  }

  private redirectToMainPage(): void {
    this.restProvider.getUserPreferences()
      .then(result => {
        if (result.length > 0) {
          let params: NavigationExtras = {
            queryParams: {
              viewId: result[0]['view_id']
            },
            skipLocationChange: true
          };
          this.router.navigate(['/mpm/mp'], params);
        } else {
          this.router.navigate(['/mpm/mp']), { skipLocationChange: true };
        }
      }, error => {
        console.error(error);
        this.showMessage('Error', error);
        if(this.isMobile())
        {
          this.indexSetting=0;
          this.retryGetSessionKeyDevice();
        }
      }).catch(ex => {
        console.error(ex);
        this.showMessage('Error', '', ex);
        if(this.isMobile())
        {
          this.indexSetting=0;
          this.retryGetSessionKeyDevice();
        }
      });
  }

}
