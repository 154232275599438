import { Component, HostListener } from "@angular/core";
import { FormatDataPipe } from "../pipes/format-data.pipe";
import { FormatDatePipe } from "../pipes/format-date.pipe";
import { DomSanitizer } from "@angular/platform-browser";
import { SharedDataService } from "../services/shared-data.service";
import { DatePipe } from "@angular/common";
import notify from 'devextreme/ui/notify';
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { Device } from '@ionic-native/device/ngx';
import hiBase64 from 'hi-base64';
import { EventsService } from "../services/events-service";
import { Pro } from '@ionic/pro';
import { custom } from 'devextreme/ui/dialog';
import {v4 as uuid} from "uuid"; 
import { RestProviderService } from '../services/rest-provider.service';
import { FingerprintAIO } from "@ionic-native/fingerprint-aio/ngx";
import { Platform } from "@ionic/angular";
import { FilePath } from "@ionic-native/file-path/ngx";
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { OpenNativeSettings } from "@ionic-native/open-native-settings/ngx";
import { DBProviderService } from "../services/db-provider.service";
import * as moment from "moment";

@Component({
  selector: 'app-parent',
  templateUrl: './parent.component.html',
  styleUrls: ['./parent.component.scss']
})

export class ParentComponent {

  fdp: FormatDataPipe = new FormatDataPipe(this.sharedData, this.domSanitizer);
  fdtp: FormatDatePipe = new FormatDatePipe();
  loadingVisible: boolean = false;

  feedWidth: number = 250;
  columnNavigationWidth=250;
  headerTitleHeight: number = 27;
  headerHeight: number = 52;
  subHeaderHeight: number = 133;
  columnViewHeaderHeight: number = 50;
  toolbarHeight: number = 38;
  toolbarHeightMobile: number = 60;
  toolbarHeightMobilePopUp: number = 56;
  cardDetailTitleHeight = 86;
  subpluginsHeight = 47;
  footerHeight: number = 45;
  columnHeight: number = 35;
  footerHeightMobile: number = 57;
  maxPopupWidth: number = window.innerWidth;
  separatorWidth: number = 0;
  maxMobileWidth: number = 1024;
  scrollHeight: number = 6;
  scrollWidth: number = 6;
  titlePopUpHeight: number = 29;
  titlePopUpHeightMobile:number = 32;
  titlePopUpHeigthIphoneXPortraitOrientation = 62;
  safeAreaTopIphoneXPortraitOrientation=30;
  addFooterHeightIPhoneXPortraitOrientation: number = 23;
  filterListToolbarMobilepHeight:number=100;
  filterListToolbarpHeight:number=35;
  addHeightPluginsMobile = 3;
  addHeighToolbarMobile = 8;
  subColumnHeaderHeigth:number=35;
  footerModalHeight:number=35;
  borderPopUps:number=8;
  minWidthCardListModalPopUp:number=450;
  minHeightCardListModalPopUp:number=300;
  minWidthPopUp:number=375;
  appInternalVersion: number = 115;
  testIphoneX: boolean = false;
  showUploadingMessage:boolean=false;
  channel: string;
  allowedExtensionsViewOnlyOffice: Array<any> = ["DOC", "DOCX", "ODT", "RTF", "TXT", "PDF", "EPUB", "XPS", "DJVU", "PPTX", "PPT", "ODP", "XLS", "XLSX", "ODS", "CSV"];
  allowedExtensionsEditOnlyOffice: Array<any> = ["DOCX", "ODT", "RTF", "EPUB", "XPS", "DJVU", "PPTX", "PPT", "ODP", "XLSX", "CSV"];
  allowedExtensionsWebDav: Array<any> = ["DOC", "DOCX", "ODT", "RTF", "EPUB", "XPS", "DJVU", "PPTX", "PPT", "ODP", "XLS", "XLSX", "ODS", "CSV"];
  allowedExtensionsDocumentUrlPdf: Array<any> = ["DOC", "DOCX", "TXT", "PDF", "PPTX", "PPT", "XLS", "XLSX"];
  allowedExtensionsOnlyOffice: Array<any> = ["DOC", "DOCX", "ODT", "RTF", "TXT", "PDF", "HTML", "EPUB", "XPS", "DJVU", "PPTX", "PPT", "ODP", "XLS", "XLSX", "ODS", "CSV"];
  lastBuild=7573880;
  mobileApp:string='bw2App';
  GUIDOverviewPlugin:string="40058332-1eac-43c5-a806-34e9c6efc21a";
  GUIDWikisPlugin:string="2f869c1d-7906-4dca-b8b4-e5fcdb68ecdb";
  objectInstanceError:string="ERROR - ERROR_CREATING_OBJECT_INSTANCE";
  numLinesTitleCard:number=2;
  numLinesTitleCardDocument:number=1;
  numLinesTitleCardTreeMode:number=1;
  numLinesTextPreviewCard:number=2;
  borderLeftNotReadItem=5;
  paddingLeftFirstColumn=12;
  paddingLeftOrRightColumn=5;
  paddingLeftOrRightColumnMobile=0;
  borderCard=3;
  paddingLeftOrRightCard=8;
  paddingLeftOrRightCellCard=3;
  itemImageCardWidth=20;
  documentImageMarginLeft=8;
  documentImageMarginRigth=20;
  documentImageWidth=80;
  iconDocumentWidth=15;
  maxWidthTabletMode=1024;
  maxWidthMobileMode=767;
  minWidthFroalaButtonsLargeDevices=800;
  minWidthFroalaButtonsMediumDevices=650;
  minWidthFroalaButtonsSmallDevices=400;
  minWidthMultipleColumnsProperties=680;
  menuHeaderHeight=70;
  minWidthMediumDevices=992;
  propertiesDocumentWizard:any[]=[
    {
      PropertyName:"",
      PropertyCaption:"",
      PropertyOrder:0,
      PropertyColumnOrder:0,
      ColumnOrder:1,
      Group:"General",
      DisplayValue:"",
      DisplayValueTmp:"",
      Position:"LR",
      Type:"RadioGroup",
      RequiresValue:true,
      ReadOnly:false,
      Options:[
        {
          Key:"OnlyOffice",
          Caption:this.fdp.transform('EditDocumentWeb', 'GetTranslationCaption')
        },
        {
          Key:"WebDav",
          Caption:this.fdp.transform('EditDocumentWebDav', 'GetTranslationCaption')
        }
      ],
    },
    {
      PropertyName:"Runtime_DocumentType_ID",
      PropertyCaption:"Document_DocumentType_ID",
      ColumnOrder:2,
      PropertyOrder:1,
      Group:"General",
      BrowseGUID:"7550dc3b-eef7-4978-bfe9-05de651354c9",
      RefGUID:"a50c4e2d-e3ea-43f8-8d67-942f8e2bb9f0",
      DisplayValue:"",
      DisplayValueTmp:"",
      Position:"LR",
      DataType:5,
      RequiresValue:true,
      ReadOnly:false,
      UserValidationRuleSQL:"DocumentType_Active=1 AND DocumentType_Template_Document_ID IS NOT NULL AND NOT EXISTS (SELECT Document_ID FROM terpDocuments WHERE Document_ID=DocumentType_Template_Document_ID AND Document_RecycleBin=1)"
    },
    {
      ColumnOrder:3,
      ColumnClass:"bw2-col-custom-left property-left",
      PropertyName:"Runtime_Name",
      PropertyCaption:"Document_Name",
      PropertyOrder:2,
      DisplayValue:"",
      DisplayValueTmp:"",
      Value:"",
      Group:"General",
      Position:"L",
      RequiresValue:true,
      ReadOnly:false,
      DataType:1
    },
    {
      ColumnClass:"bw2-col-custom-right property-right",
      ColumnOrder:4,
      PropertyName:"Runtime_Type",
      PropertyCaption:"",
      PropertyOrder:3,
      DisplayValue:"",
      DisplayValueTmp:"",
      Value:"",
      Group:"General",
      Position:"R",
      ReadOnly:true,
      RequiresValue:false,
      DataType:1
    },
    {
      ColumnOrder:5,
      PropertyName:"Runtime_Subject",
      PropertyCaption:"*_Subject",
      Value:"",
      PropertyOrder:4,
      DisplayValue:"",
      DisplayValueTmp:"",
      Group:"General",
      Position:"LR",
      ReadOnly:false,
      RequiresValue:false,
      DataType:1
    },
    {
      ColumnOrder:5,
      PropertyName:"Runtime_UseSecurityFromTemplate",
      PropertyCaption:"UseSecurityFromTemplate",
      PropertyOrder:5,
      DisplayValue:"",
      DisplayValueTmp:"",
      Value:true,
      Group:"General",
      Position:"LR",
      ReadOnly:false,
      RequiresValue:false,
      DataType:4,
    },
    {
      ColumnOrder:6,
      PropertyName:"Runtime_Account_ID",
      PropertyCaption:"*Account_ID",
      RefGUID:"7d7970fd-b57c-4e75-9966-065c6f3bd24a",
      UserValidationRuleSQL:"",
      PropertyOrder:6,
      Group:"Relations",
      DisplayValue:"",
      DisplayValueTmp:"",
      Value:0,
      BrowseGUID:"995837f1-b13f-4010-aef1-85d6b56b8797",
      Position:"L",
      ReadOnly:false,
      RequiresValue:false,
      DataType:5
    },
    {
      ColumnOrder:6,
      PropertyName:"Runtime_AccountContact_ID",
      PropertyCaption:"*AccountContact_ID",
      RefGUID:"853e0f5a-cd08-42bc-a9bc-90722cd2a318",
      UserValidationRuleSQL:"",
      PropertyOrder:7,
      Group:"Relations",
      DisplayValue:"",
      DisplayValueTmp:"",
      Value:0,
      BrowseGUID:"d21c2edb-6e2e-4e0a-a58a-9ef5ab80b4ab",
      Position:"L",
      ReadOnly:false,
      RequiresValue:false,
      DataType:5
    },
    {
      ColumnOrder:6,
      PropertyName:"Runtime_PersonnelContact_ID",
      PropertyCaption:"*_PersonnelContact_ID",
      RefGUID:"cc138050-db3e-4ea9-b8c9-c5af97554ee3",
      UserValidationRuleSQL:"",
      PropertyOrder:8,
      Group:"Relations",
      DisplayValue:"",
      DisplayValueTmp:"",
      Value:0,
      BrowseGUID:"7316f2e8-b2ae-41df-a657-053080a3f79d",
      Position:"L",
      ReadOnly:false,
      RequiresValue:false,
      DataType:5
    },
    {
      ColumnOrder:6,
      PropertyName:"Runtime_Personnel_ID",
      PropertyCaption:"*Personnel_ID",
      RefGUID:"7ef90f63-5e42-4ecf-bee2-0895f1dd2dc8",
      UserValidationRuleSQL:"",
      PropertyOrder:9,
      Group:"Relations",
      DisplayValue:"",
      DisplayValueTmp:"",
      Value:0,
      BrowseGUID:"bf18e7d7-ffc0-4899-bb91-76b7411fdb6e",
      Position:"L",
      ReadOnly:false,
      RequiresValue:false,
      DataType:5
    },
    {
      ColumnOrder:6,
      PropertyName:"Runtime_Item_ID",
      PropertyCaption:"*_Item_ID",
      Group:"Relations",
      PropertyOrder:10,
      Hidden:true,
      Value:0,
      DisplayValue:"",
      DisplayValueTmp:"",
      RequiresValue:false,
      Position:"L",
      DataType:3
    },
    {
      ColumnOrder:6,
      PropertyName:"Runtime_Account_AdditionalAddress_ID",
      PropertyCaption:"*_AdditionalAddress_ID",
      Group:"Relations",
      ReadOnly:true,
      RefGUID:"87fb1039-d801-404f-8883-843da08339bd",
      PropertyOrder:11,
      BrowseGUID:"fe4b0165-b4da-45fe-b22f-707709f8357e",
      UserValidationRuleSQL:"",
      Position:"L",
      Value:0,
      DisplayValue:"",
      DisplayValueTmp:"",
      Hidden:true,
      RequiresValue:false,
      DataType:5
    },
    {
      ColumnOrder:7,
      PropertyName:"Runtime_Project_ID",
      PropertyCaption:"*_Project_ID",
      RefGUID:"87fb1039-d801-404f-8883-843da08339bd",
      UserValidationRuleSQL:"",
      PropertyOrder:12,
      Group:"Relations",
      DisplayValue:"",
      DisplayValueTmp:"",
      Value:0,
      BrowseGUID:"37fb636f-8d25-44f6-ac07-ca80c335963e",
      Position:"R",
      ReadOnly:false,
      RequiresValue:false,
      DataType:5
    },
    {
      ColumnOrder:7,
      PropertyName:"Runtime_ProjectTask_ID",
      PropertyCaption:"*_ProjectTask_ID",
      RefGUID:"dc1b3b58-ad0f-4f81-9428-23ef7f0a80ce",
      UserValidationRuleSQL:"",
      PropertyOrder:13,
      Group:"Relations",
      DisplayValue:"",
      DisplayValueTmp:"",
      Value:0,
      BrowseGUID:"41b15e4b-6c18-4ff3-a376-4d335ce94839",
      Position:"R",
      ReadOnly:true,
      RequiresValue:false,
      DataType:5
    },
    {
      ColumnOrder:7,
      PropertyName:"Runtime_Company_ID",
      PropertyCaption:"*Company_ID",
      PropertyOrder:14,
      Group:"Relations",
      DisplayValue:"",
      DisplayValueTmp:"",
      Value:0,
      BrowseGUID:"50d4de5e-f139-4d78-a861-38b10a6a5691",
      UserValidationRuleSQL:"",
      Position:"R",
      Hidden:true,
      ReadOnly:false,
      RequiresValue:false,
      DataType:5
    },
    {
      ColumnOrder:7,
      PropertyName:"Runtime_CompanySite_ID",
      PropertyCaption:"*CompanySite_ID",
      RefGUID:"d1314d5f-81ae-4906-bd93-570d4ce65827",
      UserValidationRuleSQL:"",
      PropertyOrder:15,
      Group:"Relations",
      DisplayValue:"",
      DisplayValueTmp:"",
      Value:0,
      BrowseGUID:"d24708b3-a6a3-45a1-bb24-35e4a6ce4e52",
      Position:"R",
      ReadOnly:false,
      RequiresValue:false,
      DataType:5
    },
    {
      ColumnOrder:7,
      PropertyName:"Runtime_AssignedTo_Personnel_ID",
      PropertyCaption:"Document_Personnel_ID",
      RefGUID:"7ef90f63-5e42-4ecf-bee2-0895f1dd2dc8",
      UserValidationRuleSQL:"",
      PropertyOrder:16,
      Group:"Relations",
      DisplayValue:"",
      DisplayValueTmp:"",
      Value:0,
      BrowseGUID:"bf18e7d7-ffc0-4899-bb91-76b7411fdb6e",
      Position:"R",
      ReadOnly:false,
      RequiresValue:false,
      DataType:5
    },
    {
      ColumnOrder:8,
      PropertyName:"Runtime_Case_ID",
      PropertyCaption:"*_ModuleHelpdesk",
      RefGUID:"a0f84be8-606a-47f5-bf33-1de5b3c99076",
      UserValidationRuleSQL:"",
      PropertyOrder:17,
      DisplayValue:"",
      DisplayValueTmp:"",
      Value:0,
      BrowseGUID:"3fcfbdce-e273-49d1-8a93-b06950205df7",
      Group:"More...",
      Position:"L",
      ReadOnly:false,
      RequiresValue:false,
      DataType:5
    },
    {
      ColumnOrder:8,
      PropertyName:"Runtime_Activity_ID",
      PropertyCaption:"*_ModuleActivities",
      RefGUID:"1600671f-bece-410d-86c9-d5f58c3235fc",
      UserValidationRuleSQL:"",
      PropertyOrder:18,
      DisplayValue:"",
      DisplayValueTmp:"",
      Value:0,
      Group:"More...",
      BrowseGUID:"8544b007-0421-49f9-9944-8270b27ec42e",
      Position:"L",
      ReadOnly:false,
      RequiresValue:false,
      DataType:5
    },
    {
      ColumnOrder:8,
      PropertyName:"Runtime_Service_ID",
      PropertyCaption:"*_ModuleServices",
      RefGUID:"8b27cf7d-9991-41de-b44d-2104bad84d00",
      UserValidationRuleSQL:"",
      PropertyOrder:19,
      DisplayValue:"",
      DisplayValueTmp:"",
      Value:0,
      Group:"More...",
      BrowseGUID:"16e3a84c-47dc-4ba5-988e-975730e4dd4d",
      Position:"L",
      ReadOnly:false,
      RequiresValue:false,
      DataType:5,
    },
    {
      ColumnOrder:8,
      PropertyName:"Runtime_ProjectAccount_ID",
      PropertyCaption:"*ProjectAccount_ID",
      PropertyOrder:20,
      UserValidationRuleSQL:"",
      Group:"More...",
      Value:0,
      DisplayValue:"",
      DisplayValueTmp:"",
      DataType:3,
      Position:"L",
      ReadOnly:true,
      RequiresValue:false,
    },
    {
      ColumnOrder:9,
      PropertyName:"Runtime_SalesDocument_ID",
      PropertyCaption:"*_ModuleSales",
      RefGUID:"28fb58c1-6d42-441e-b344-145f56c38b9f",
      UserValidationRuleSQL:"",
      PropertyOrder:21,
      DisplayValue:"",
      DisplayValueTmp:"",
      Value:0,
      Group:"More...",
      BrowseGUID:"19e9d070-118b-478d-8497-20c2968e97d3",
      Position:"R",
      ReadOnly:false,
      RequiresValue:false,
      DataType:5
    },
    {
      ColumnOrder:9,
      PropertyName:"Runtime_PurchaseDocument_ID",
      PropertyCaption:"*_ModulePurchase",
      RefGUID:"01a34fbc-8fc3-4967-95ca-476e487bfbeb",
      UserValidationRuleSQL:"",
      PropertyOrder:22,
      DisplayValue:"",
      DisplayValueTmp:"",
      Value:0,
      Group:"More...",
      BrowseGUID:"1b7d87ba-2dcd-4407-8a3f-3297cb869963",
      Position:"R",
      ReadOnly:false,
      RequiresValue:false,
      DataType:5
    },
    {
      ColumnOrder:9,
      PropertyName:"Runtime_FinancialDocument_ID",
      PropertyCaption:"*FinancialDocument_ID",
      RefGUID:"a74db288-96ae-4b12-92ec-a5080dd2bc41",
      UserValidationRuleSQL:"",
      PropertyOrder:23,
      DisplayValue:"",
      DisplayValueTmp:"",
      Value:0,
      Group:"More...",
      BrowseGUID:"282beb9f-d903-45e9-bec8-466ef5545769",
      Position:"R",
      ReadOnly:false,
      RequiresValue:false,
      DataType:5
    }
  ]
  
  constructor(
    public restProvider:RestProviderService,
    public events: EventsService,
    public domSanitizer: DomSanitizer,
    public sharedData: SharedDataService,
    public device: Device,
    public screenOrientation: ScreenOrientation) {
  }

  public showToolTipCaption(event:any,target:any,caption:string){
    if(target.offsetWidth < target.scrollWidth){
      this.executeShowToolTip(event,caption);
    }
  }

  public isFroala(prop:any){
    if(prop.Format == this.sharedData.FormControlFormatHTML || prop.Format == this.sharedData.FormControlFormatBW2HTML)
    {
      return true;
    }else{
      return false;
    }
  }

  public removeCardDetailPopUpsDeleted(card:any){
    if(this.sharedData.listCardDetailPopUp && this.sharedData.listCardDetailPopUp.length>0)
    {
      let listCardDetailPopUp=this.sharedData.listCardDetailPopUp.filter(item => item['card'].ID != card.ID);
      if(listCardDetailPopUp==undefined)
        listCardDetailPopUp=[];
      this.sharedData.listCardDetailPopUp=listCardDetailPopUp;
    }
  }

  public verifyAndUpdateCardDetailPopUps(card:any){
    if(this.sharedData.listCardDetailPopUp.length>0)
    {
      let listCardDetailPopUp=this.sharedData.listCardDetailPopUp.filter(item => card && item['card'].ID == card.ID);
      if(listCardDetailPopUp && listCardDetailPopUp.length>0)
      {
        listCardDetailPopUp.forEach(popupdata => {
            if(popupdata && popupdata.cardDetailPage)
            {
              popupdata.cardDetailPage.parentCard=card;
              popupdata.cardDetailPage.updateCardDetailData();
              if(popupdata.cardDetailPage)
              {
                popupdata.cardDetailPage.enableCardOptions=true;
                this.showHiddenMessage();
              }
            }
        });
      }
    }
  }

  public getMenuOptionsContainerHeight(){
    let height=window.innerHeight-this.menuHeaderHeight;
    if(this.isIPhoneXPortraitOrientation())
    {
      height=height-this.safeAreaTopIphoneXPortraitOrientation;
    }
    return height;
  }

  public getMenuWidth(){
     if(this.isTabletMode()){
      return this.sharedData.menuWidthTabletMode;
    }else{
      return this.sharedData.menuWidth;
    }
  }

  public getPopUpHeigth():number{
    return Math.round(window.innerHeight * 0.95);
  }

  public getDocumentActions(ext:any,editAttachments:boolean,platform:Platform){
    let listActions:any[]=[];
    let action:any={};
    action.Key='View';
    action.PictureID='nu-preview.png';
    action.PictureType='assetImage';
    action.text=this.fdp.transform('view', 'GetTranslationCaption');
    action.isDocument=true;
    action.mode='readonly';
    listActions.push(action);
    let aext = this.allowedExtensionsWebDav.find(ae => ae == ext.toUpperCase());
    if(aext && !platform.is('ios'))
    {
      action={};
      action.Key='WebDav';
      action.PictureID='document_view_office.png';
      action.PictureType='assetImage';
      action.text=this.fdp.transform('ViewDocumentWebDav', 'GetTranslationCaption');
      action.isDocument=true;
      action.mode='readonly';
      listActions.push(action);
      if(editAttachments)
      {
        action={};
        action.Key='WebDav';
        action.PictureID='document_edit_office.png';
        action.PictureType='assetImage';
        action.text=this.fdp.transform('EditDocumentWebDav', 'GetTranslationCaption');
        action.isDocument=true;
        action.mode='write';
        listActions.push(action);
      }
    }
    aext = this.allowedExtensionsViewOnlyOffice.find(ae => ae == ext.toUpperCase());
    if(aext)
    {
      action={};
      action.Key='OnlyOffice';
      action.PictureID='document_view_browser.png';
      action.PictureType='assetImage';
      action.text=this.fdp.transform('ViewDocumentWeb', 'GetTranslationCaption');
      action.isDocument=true;
      action.mode='readonly';
      listActions.push(action);
    }
    aext = this.allowedExtensionsEditOnlyOffice.find(ae => ae == ext.toUpperCase());
    if(aext && editAttachments)
    {
      action={};
      action.Key='OnlyOffice';
      action.PictureID='document_edit_browser.png';
      action.PictureType='assetImage';
      action.text=this.fdp.transform('EditDocumentWeb', 'GetTranslationCaption');
      action.isDocument=true;
      action.mode='write';
      listActions.push(action);
    }
    return listActions;
  }

  public getFilterGroups(listSelectedFilters:any):any[]{
    let listGroups:any[]=[];
    listSelectedFilters.forEach(filter => {
      if(filter['GridFilter_ORKey'] && filter['GridFilter_ORKey']!="")
      {
        let group=listGroups.find(group=>group['ID']==filter['GridFilter_ORKey']);
        if(group && group.listFilters)
        {
          group.listFilters.push(filter);
        }else{
          let newGroup={
            ID:filter['GridFilter_ORKey'],
            listFilters:[filter]
          }
          listGroups.push(newGroup);
        }
      }else{
        let group=listGroups.find(group=>group['ID']==filter['GridFilter_ID']);
        if(group && group.listFilters)
        {
          group.listFilters.push(filter);
        }else{
          let newGroup={
            ID:filter['GridFilter_ID'],
            listFilters:[filter]
          }
          listGroups.push(newGroup);
        }
      }
    });
    return listGroups;
  }

  public transformListSelectedFilters(filters:any[]):any[]{
    let listSelectedFilters:any=[];
    try {
      let list =filters;
      if(list && list.length>0)
      {
        list.forEach(element => {
         let sf=JSON.parse(element);
         listSelectedFilters.push(sf)
        });
        listSelectedFilters=this.getListFiltersDecodeBase64(listSelectedFilters);
        this.orderFilterInToolbar(listSelectedFilters);
        this.separateCaptionAndTooltipInFilters(listSelectedFilters);
      }
      return listSelectedFilters;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  public getAppResources(){
    this.restProvider.executeMethod("BW2Mobile_GetAppResources", {}).then(
      result => {
        let data = JSON.parse(result);
        this.sharedData.URLNormalizeCSS=data["URLNormalizeCSS"];
        this.sharedData.URLPreviewCSS=data["URLPreviewCSS"];
        this.sharedData.URLHighlightCSS=data["URLHighlightCSS"];
        this.sharedData.URLHighlightJS=data["URLHighlightJS"];
        this.sharedData.URLPreviewJS=data["URLPreviewJS"];
        this.setResources();
      },
      error => {
        console.error(error);
      });
  }


  public setSelectedIdsListCards(cards:any[]){
    if(cards.length>0)
    {
      cards.forEach(card => {
        card.selectedId=uuid();
      });
    }
    return cards;
  }

  public separateCaptionAndTooltipInFilters(listSelectedFilters:any[]):void{
    if(listSelectedFilters.length>0)
    {
      listSelectedFilters.forEach(filter => {
        let gfo=filter["GridFilter_Caption"].split("|");
        if(gfo.length>1)
        {
          filter["GridFilter_Caption"]=gfo[0];
          filter["GridFilter_Tooltip"]=gfo[1];
        }
      });
    }
  }

  public groupDocumentWizardProperties(template:any,platform:Platform): any[] {
    let properties=JSON.parse(JSON.stringify(this.propertiesDocumentWizard));
    let documentTypeProperty=properties.find(item=>item.PropertyName=='Runtime_DocumentType_ID');
    documentTypeProperty.Value=template.ID;
    documentTypeProperty.DisplayValue=template.Title;

    let AssignedToPersonnelProperty=properties.find(item=>item.PropertyName=='Runtime_AssignedTo_Personnel_ID');
    AssignedToPersonnelProperty.Value=this.sharedData.portalUserID;
    AssignedToPersonnelProperty.DisplayValue=this.sharedData.portalUserDisplayName;

    if(platform.is('ios'))
    {
      let editOptions=properties.find(item=>item.PropertyName=='');
      editOptions.Hidden=true;
    }
    properties.forEach(prop => {
      if (this.isMobileMode()) {
        prop.classLabelColumn = '';
        prop.classValueColumn = 'bw2-col';
      } else {
        prop.classLabelColumn = 'bw2-col-1';
        if(prop.PropertyCaption && prop.PropertyCaption!='')
        {
          prop.classValueColumn = 'bw2-col-10';
        }else{
          prop.classValueColumn = 'bw2-col';
        }
      }
    });

    let groups = [];

    properties.forEach(property => {
      if (property['Group']) {
        let gn = property['Group'].split(',');
        let showGroup: boolean = true;
        let groupName: string = gn[0];
        if(property.Position !=undefined && property.Position !="")
        {
          if(property.Position=="R")
          {
            property.PropertyOrderTmp = property.PropertyOrder + 200;
          }
          if(property.Position=="L")
          {
            property.PropertyOrderTmp = property.PropertyOrder + 100;
          }
        }
        if (groupName != undefined && groupName != '') {
          let group = groups.find(g => g.name == groupName);
          if (group) {
            if (!group.hasHiddenProperties) {
              group.hasHiddenProperties = property['PropertyHidden'];
            }
            group.items.push(property);
          } else {
            groups.push({
              name: groupName,
              order: property['GroupOrder'],
              items: [property],
              show: showGroup,
              hasHiddenProperties: property['PropertyHidden'],
              showHiddenProperties: false
            });
          }
        }
      }
    });


    groups.forEach(group => {
      group.columns = [];
      group.items.forEach(item => {
        if(group.columns.length==0)
        {
          let column:any={
            ColumnOrder:item.ColumnOrder,
            properties:[]
          }
          if(item.ColumnClass)
          {
            column.notChangeColumnClass=true;
            column.columnClass=item.ColumnClass;
          }
          if(item.Position=="LR")
          {
            column.typeColumn='fullColumn';
          }
          if(item.Position=="L")
          {
            column.typeColumn='leftColumn';
          }
          if(item.Position=="R")
          {
            column.typeColumn='rigthColumn';
          }
          column.properties.push(item);
          group.columns.push(column);
        }else{
          let column=group.columns.find(col=>col['ColumnOrder']==item.ColumnOrder);
          if(column)
          {
            column.properties.push(item);
          }else{
            let column:any={
              ColumnOrder:item.ColumnOrder,
              properties:[]
            }
            if(item.ColumnClass)
            {
              column.notChangeColumnClass=true;
              column.columnClass=item.ColumnClass;
            }
            if(item.Position=="LR")
            {
              column.typeColumn='fullColumn';
            }
            if(item.Position=="L")
            {
              column.typeColumn='leftColumn';
            }
            if(item.Position=="R")
            {
              column.typeColumn='rigthColumn';
            }
            column.properties.push(item);
            group.columns.push(column);
          }
        }
      });

      group.columns.forEach(column => {
        if(!column.notChangeColumnClass)
        {
          if(column.typeColumn=='fullColumn')
          {
            column.columnClass='bw2-col';
          }
          if(column.typeColumn=='leftColumn')
          {
            if (this.isMobileMode()) {
              column.columnClass='bw2-col';
            } else {
              column.columnClass='bw2-col-6 property-left';
            }
          }
          if(column.typeColumn=='rigthColumn')
          {
            if (this.isMobileMode()) {
              column.columnClass='bw2-col';
            } else {
              column.columnClass='bw2-col-6 property-right';
            } 
          }
        }
      });

      if(group && group.columns && group.columns.length>0)
      {
        group.columns.forEach(column => {
          if(column.properties && column.properties.length>0)
          {
            let subGroups=[];
            column.properties.forEach(property => {
              if(property.SubGroup !=undefined)
              {
                if(subGroups.length==0)
                {
                  let subGroup={
                    numSubGroup:property.SubGroup,
                    properties:[]
                  };
                  subGroup.properties.push(property);
                  subGroups.push(subGroup);
                }else{
                  let idx = subGroups.findIndex(s => s.numSubGroup == property.SubGroup);
                  if(idx==-1)
                  {
                    let subGroup={
                      numSubGroup:property.SubGroup,
                      properties:[]
                    };
                    subGroup.properties.push(property);
                    subGroups.push(subGroup);
                  }else{
                    let subGroup = subGroups.find(s => s.numSubGroup == property.SubGroup);
                    subGroup.properties.push(property);
                  }
                }
              }
            }); 
            column.subGroups=subGroups;
          }
        });
      }
    });
    return groups;
  }

  public hideAllContextMenu(){
    this.sharedData.currentContextMenu={};
    if(this.sharedData.contextMenu && this.sharedData.contextMenu.instance)
    {
      this.sharedData.contextMenu.instance.hide();
    }
  }
  
  @HostListener('window:orientationchange ', ['$event'])
  public onOrientationChange(event): void {
   this.hideAllContextMenu();
  }

  public showMessageConfigureBiometricAuth(ons:OpenNativeSettings,message:string){
    let html:string='<div style="display:flex;align-items:center">'+message+'<div>';
    let options = {
      title: this.fdp.transform(this.sharedData.captionKeyPrefix+'Message', 'GetTranslationCaption'),
      messageHtml: html,
      buttons: [
        {
          text: this.fdp.transform(this.sharedData.captionKeyPrefix+'Setup', 'GetTranslationCaption'),
          onClick: (() => 'setup')
        }, {
          text: this.fdp.transform('cancel', 'GetTranslationCaption'),
          onClick: (() => 'cancel')
        }
      ]
    }
    let dialog = custom(options);
    dialog.show().done(res => {
      switch (res) {
        case 'setup':this.openNativeSettings(ons);
          break;
        case 'cancel':
         break;
        default:
          break;
      }
    });
  }

  public openNativeSettings(ons:OpenNativeSettings){
    let setting:string="settings"
    this.executeOpenNativeSettings(setting,ons);
  }

  private executeOpenNativeSettings(setting:any,ons:OpenNativeSettings){
    if(setting!='')
    {
      ons.open(setting).then(val =>{
        this.sharedData.openedNativeSettings=true;
      }).catch(error=>{
        console.log(error);
      });
    }
  }

  public verifyIfAvailableBiometricAuthentication(faio: FingerprintAIO): Promise<any>{
    return new Promise((resolve, reject) => {
      faio.isAvailable().then((result: any) => {
        resolve(result);
      })
      .catch((error: any) => {
        reject(error);
      });
    });
  }

  public getPictureFromCamera(options:CameraOptions,camera:Camera,platform:Platform,filePath:FilePath): Promise<any>{
    return new Promise((resolve, reject) => {
      this.sharedData.disableOnResumeSubscription=true;
      camera.getPicture(options).then((imageData) => {
        if (platform.is('ios') ) {
          resolve(imageData);
          setTimeout(() => {
            this.sharedData.disableOnResumeSubscription=false;
          }, 500);
        }else if (platform.is('android')){
            filePath.resolveNativePath(imageData)
          .then(filePath => {
            resolve(filePath);
            setTimeout(() => {
              this.sharedData.disableOnResumeSubscription=false;
            }, 500);
          })
          .catch(err => {
            reject(err);
            setTimeout(() => {
              this.sharedData.disableOnResumeSubscription=false;
            }, 500);
          });
        }
      }, (err) => {
        reject(err);
      });
    });
  }

  public manageBiometricAccessError(faio:FingerprintAIO,platform:Platform,error:any,parentPage:any,lastSetting?:any):any{
    console.error(error.code + ":" + error.message);
    this.sharedData.currentBiometricError={};
    this.sharedData.currentBiometricError.parentPage=parentPage;
    if(error.code == faio.BIOMETRIC_DISMISSED || error.code == faio.BIOMETRIC_UNKNOWN_ERROR)
    {
      this.sharedData.showBiometricMessageScreen=true;
      if(lastSetting)
      {
        this.sharedData.currentBiometricError.lastSetting=lastSetting;
      }
      this.sharedData.currentBiometricError.message=this.fdp.transform(this.sharedData.captionKeyPrefix+"BiometricDismissedMessage", 'GetTranslationCaption');
      this.sharedData.currentBiometricError.showTryAgainButton=true;
      this.showHiddenMessage();
    }else if(error.code == faio.BIOMETRIC_LOCKED_OUT || error.code == faio.BIOMETRIC_LOCKED_OUT_PERMANENT)
    {
      this.sharedData.showBiometricMessageScreen=true;
      if(lastSetting)
      {
        this.sharedData.currentBiometricError.lastSetting=lastSetting;
      }
      if(platform.is('android'))
      {
        this.sharedData.currentBiometricError.message=this.fdp.transform(this.sharedData.captionKeyPrefix+"BiometricLockedOutMessage", 'GetTranslationCaption');
        this.showHiddenMessage();
        setTimeout(() => {
          this.sharedData.currentBiometricError.showTryAgainButton=true;
          this.showHiddenMessage();
        }, 30000);
      }else{
        this.sharedData.currentBiometricError.message=this.fdp.transform(this.sharedData.captionKeyPrefix+"BiometricLockedOutMessageIOS", 'GetTranslationCaption');
        this.sharedData.currentBiometricError.showEnterCodeButton=true;
        this.showHiddenMessage();
      }
    }else if (error.code == faio.BIOMETRIC_AUTHENTICATION_FAILED || error.code == faio.BIOMETRIC_SCREEN_GUARD_UNSECURED) {
      this.sharedData.showBiometricMessageScreen=true;
      if(lastSetting)
      {
        this.sharedData.currentBiometricError.lastSetting=lastSetting;
      }
      this.sharedData.currentBiometricError.message=this.fdp.transform(this.sharedData.captionKeyPrefix+"BiometricAuthenticationFailedMessage", 'GetTranslationCaption');
      this.sharedData.currentBiometricError.showTryAgainButton=true;
      this.showHiddenMessage();
    }else{
      this.sharedData.showBiometricMessageScreen=true;
      if(lastSetting)
      {
        this.sharedData.currentBiometricError.lastSetting=lastSetting;
      }
      this.sharedData.currentBiometricError.message=this.fdp.transform(this.sharedData.captionKeyPrefix+"BiometricPluginErrorMessage", 'GetTranslationCaption')+"_"+error.code + ":" + error.message;
      this.sharedData.currentBiometricError.showTryAgainButton=true;
      this.showHiddenMessage();
    }
  }


  public verifyIfEnterApp(dbProvider:DBProviderService,faio: FingerprintAIO,platform:Platform): Promise<any>{
    return new Promise((resolve, reject) => {
      if (this.sharedData.biometricAccessEnabled) {
        this.manageVerifyAvailableBiometricAuthentication(dbProvider,faio,platform).then(res => {
          resolve(true);
        }, error => {
          reject(error);
        });
      }else{
        this.sharedData.currentBiometricError={};
        this.sharedData.showBiometricMessageScreen=false;
        this.sharedData.biometricAccessEnabled = false;   
        this.showHiddenMessage();     
        dbProvider.updateAuthenticationType("password");
        resolve(true);
      }
    });
  }

  public executeBiometricAuthentication(faio:FingerprintAIO,platform:Platform) : Promise<any>{
    return new Promise((resolve, reject) => {
      this.sharedData.currentBiometricError={};
      this.showHiddenMessage();
      this.validateBiometricAuthentication(faio,platform).then(res => {
        this.sharedData.showBiometricMessageScreen=false;
        this.showHiddenMessage();
        resolve(true);
      }, error => {
        this.manageBiometricAccessError(faio,platform,error,this);
        reject(error);
      });
    });
  }

  private manageVerifyAvailableBiometricAuthentication(dbProvider:DBProviderService,faio:FingerprintAIO,platform:Platform,notRecallMethod?:boolean): Promise<any>{
    return new Promise((resolve, reject) => {
      this.sharedData.currentBiometricError={};
      this.sharedData.showBiometricMessageScreen=true;
      this.showHiddenMessage();
      this.verifyIfAvailableBiometricAuthentication(faio).then(res => {
        if(notRecallMethod)
        {
          this.executeBiometricAuthentication(faio,platform).then(res => {
            resolve(true);
          }, error => {
            reject(error);
          });
        }else{
          setTimeout(() => {
            this.manageVerifyAvailableBiometricAuthentication(dbProvider,faio,platform,true).then(res => {
              resolve(true);
            }, error => {
              reject(error);
            });
          }, 500);
        }
      }, error => {        
        console.error(error.code + ":" + error.message);
        if(platform.is('ios') && error.code == faio.BIOMETRIC_UNAVAILABLE && error.message == this.sharedData.biometryDeniedByUserMessage){
          this.executeBiometricAuthentication(faio,platform).then(res => {
            resolve(true);
          }, error => {
            reject(error);
          });
        }else if(platform.is('ios') && error.code == faio.BIOMETRIC_UNKNOWN_ERROR && 
        (error.message==this.sharedData.biometryLockedOutMessage || error.message==this.sharedData.biometryPasscodeLockedOutMessage))
        {
          this.sharedData.currentBiometricError.parentPage=this;
          this.sharedData.showBiometricMessageScreen=true;
          this.sharedData.currentBiometricError.message=this.fdp.transform(this.sharedData.captionKeyPrefix+"BiometricLockedOutMessageIOS", 'GetTranslationCaption');
          this.sharedData.currentBiometricError.showEnterCodeButton=true;
          this.showHiddenMessage();
          reject(error);
        }else{
          this.sharedData.showBiometricMessageScreen=false;
          this.showHiddenMessage();
          this.sharedData.biometricAccessEnabled = false;        
          dbProvider.updateAuthenticationType("password");
          resolve(true);
        }
      });
    });
  }


  public validateBiometricAuthentication(faio: FingerprintAIO,platform:Platform): Promise<any>{
    let title=this.fdp.transform(this.sharedData.captionKeyPrefix+'BiometricAccess', 'GetTranslationCaption');;
    let options={};
    if(platform.is('ios'))
    {
        options={
          title: title,
          cancelButtonTitle:this.fdp.transform('Cancel', 'GetTranslationCaption'),
        }
    }else{
      options={
        title: title,
        disableBackup:true,
        cancelButtonTitle:this.fdp.transform('Cancel', 'GetTranslationCaption')
      }
    }
    return new Promise((resolve, reject) => {
        this.sharedData.disableOnResumeSubscription=true;
        faio.show(options)
        .then((result: any) => {
          setTimeout(() => {
            this.sharedData.disableOnResumeSubscription=false;
            resolve(true);
          }, 250);
        })
        .catch((error: any) => {
          setTimeout(() => {
            this.sharedData.disableOnResumeSubscription=false;
            reject(error);
          }, 250);
        });
    });
  }

  public executeGetSessionKeyDevice(setting:any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.sharedData.deviceGuid = setting['device_guid'];
      this.sharedData.portalGuid = setting['portal_guid'];
      this.restProvider.executeHttpMethod('GetSessionKeyDevice', {})
        .subscribe(result => {
          let keys = JSON.parse(result);
          this.sharedData.sessionKey = keys.sessionKey;
          if (!keys.applicationUrl.endsWith('/'))
            keys.applicationUrl = keys.applicationUrl + '/';
          this.sharedData.appURL = keys.applicationUrl + 'GetContent.aspx';
          this.getAppResources();
          resolve(true);
        }, error => {
          console.error(error);
          this.showMessage('Error', JSON.stringify(error));
          reject(error);
        });
    });
  }

  public resetCurrentPortal():void{
    if(this.sharedData.currentPortal)
    {
      this.sharedData.currentPortalTmp=this.sharedData.currentPortal;
      this.sharedData.deviceGuid = this.sharedData.currentPortal['device_guid'];
      this.sharedData.portalGuid = this.sharedData.currentPortal['portal_guid'];
      this.sharedData.sessionKey=this.sharedData.currentPortal['session_key'];
      this.sharedData.appURL=this.sharedData.currentPortal['app_URL'];
      this.getAppResources();
    }
  }

  public getWidthTextPreview(card:any){
    let width:number=0;
    if(card.DocumentGUID!=undefined)
    {
        width=card.width-this.borderCard - this.documentImageMarginLeft - this.documentImageMarginRigth -
         this.documentImageWidth - 2 * this.paddingLeftOrRightCard;
    }else{
      width=card.width-this.borderCard-2*this.paddingLeftOrRightCard;
    }
    if(card.IsItemRead!=undefined && card.IsItemRead==false)
    {
      width=width-this.borderLeftNotReadItem;
    }
    return width;
  }

  public getWidthTitleCard(card:any){
    let width:number=0;
    if(card.DocumentGUID!=undefined)
    {
        width=card.width-this.borderCard - this.documentImageMarginLeft - this.documentImageMarginRigth -
         this.documentImageWidth - 2 * this.paddingLeftOrRightCard - this.iconDocumentWidth;
    }else{
      if(card.TitleRight!=undefined && card.TitleRight!='')
      {
        width=(card.width-this.borderCard- 2 * this.paddingLeftOrRightCard)* 0.8;
      }else{
        width=(card.width-this.borderCard- 2 * this.paddingLeftOrRightCard);
      }
      if(card.Icon!=undefined)
      {
        width=width-this.itemImageCardWidth;
      }
    }
    return width;
  }

  public measureText(string:string, fontSize:number) {
    fontSize=fontSize+2;
    let canvas:any = document.createElement("canvas"); 
    let context:any = canvas.getContext("2d"); 
    let font = fontSize+"px  system-ui"; 
    context.font = font; 
    let width:number = context.measureText(string).width;
    return Math.ceil(width);
  }
    
  public getDataBrowseFromParentFilter(parentFilter:any,parentPage:any):void{
    let popupHeight=0;
    if (this.isMobileMode()) {
      popupHeight = window.innerHeight;
    } else {
      popupHeight = window.innerHeight * 0.95;
    }
    let preference=this.getUserPreferencesBrowse(parentFilter['GridFilter_BusinessObject_GUID']);
    let params:any = {
      itemGuid: parentFilter['GridFilter_ListboardBrowse_GUID'],
      browseFilterId:parentFilter['GridFilter_ID'],
      selectedView:"DefaultListView",
      groupBy:"",
      orderBy:"",
      controlWidth:this.getControlWidthModalPopUp()
    };
    let queryTop=this.sharedData.queryTop;
    let listSelectedFiltersBrowse:any[]=[];
    let listGroupFilters:any[]=[];
    if(preference)
    {   
      if(preference['view_key']=='TableView_Medium')
      {
        preference['view_key']=this.sharedData.DefaultTableView;
      }   
      if (preference['filters'])
      {
        try {
          listSelectedFiltersBrowse=this.transformListSelectedFilters(preference['filters']);
          listGroupFilters=this.getFilterGroups(listSelectedFiltersBrowse);
          params.filters=preference['filters'];
        } catch (error) {
          console.error(error);
        }
      }
      if(preference['group_id'])
      {
        params.groupBy=preference['group_id'];
      }
      if(preference['order_id'])
      {
        params.orderBy=preference['order_id'];
      }else{
        if(parentFilter['GridFilter_ListboardBrowse_GUID'] == '47c11407-a9ee-4e5a-8efe-75e971f0fe11') { //dimension
          params.orderBy='Dimension_ID';
        }
      }
      if(preference['view_key'])
      {
        params.selectedView=preference['view_key'];
      }
      if(preference['top'])
      {
        queryTop=preference['top'];
      }
    }
    params.minWidthPreview=0;
    this.restProvider.executeMethod("BW2Mobile_GetDataJson", params).then(
      result => {
        try {
          let data = JSON.parse(result);
          let moreItems = false;
          if (data['Cards'].length > queryTop) {
            data['Cards'] = data['Cards'].slice(0,queryTop);
            moreItems = true;
          }
          let tableView:boolean=params.selectedView==this.sharedData.DefaultTableView?true:false;
          let browseParams={
            browseGuid:parentFilter['GridFilter_BusinessObject_GUID'],
            subColumns:data['SubColumns'],
            queryTop:queryTop,
            selectedView:params.selectedView,
            groupBy:params.groupBy,
            groupByDirection : preference['group_direction']?preference['group_direction']:'ASC',
            orderBy:params.orderBy,
            orderByDirection :  preference['order_direction']?preference['order_direction']:'ASC',
            listSelectedFilters:listSelectedFiltersBrowse,
            listGroupFilters:listGroupFilters,
            listCards:data['Cards'],
            listColumns:data['Columns'],
            moreItems:moreItems,
            tableView:tableView,
            modalParams:params,
            popupHeight:popupHeight,
            itemGuid:parentFilter['GridFilter_ListboardBrowse_GUID']
          }
          let popupdata={
            browseParams:browseParams,
            titlePopUp:parentFilter['GridFilter_Caption'],
            parentPage:parentPage,
            parentFilter:parentFilter
          }
          this.addCardListModalPopUp(popupdata);
        } catch (error) {
          console.error(error);
          this.showMessage('Error', error);
        }
      },
      error => {
        console.error(error);
        this.showMessage('Error', error);
      });
}

  public getTextLinesFromtext(font:any,text:string,widthText:number,maxNumLines:number){
    try{
      let widthAllText=this.measureText(text,font); 
      let numCharactersPerLine:number=Math.round(widthText * text.length / widthAllText)-3;
      let truncated=false;
      let lineText:string="";
      let words=text.split(" ");
      let textLines=[];
      let numWords=words.length;
   
      if (numWords > 0) {
        for (let i = 0; i < numWords; i++) {
          if (lineText == "") {
            lineText = words[i];
            if (i == words.length - 1) {
              let resultWidth=this.measureText(lineText,font);
              if (resultWidth > widthText) {
                lineText = lineText.substr(0, numCharactersPerLine - 6) + "...";
                truncated = true;
              }
              textLines.push(lineText);
            }
          } else {
            let lineTextTmp = lineText + " " + words[i];
            let resultWidth=this.measureText(lineTextTmp,font);
            if (resultWidth > widthText) {
              if (textLines.length == maxNumLines - 1) {
                lineText = lineTextTmp.substr(0, numCharactersPerLine - 6);
                textLines.push(lineText + "...");
                truncated = true;
                break;
              } else {
                textLines.push(lineText);
                lineText = words[i];
                if (i == words.length - 1) {
                  textLines.push(lineText);
                }
              }
            } else {
              lineText = lineText + " " + words[i];
              if (i == words.length - 1) {
                textLines.push(lineText);
              }
            }
          }
        }
      }
      
      let result={
        textLines:textLines,
        truncated:truncated
      }
      return result;
    }catch(e){
      console.log(e);
      return undefined;
    }
  }

  public getWidthPopUp(){
    if(this.isMobileMode())
    {
      return window.innerWidth;
    }else{
      return  Math.round(window.innerWidth * 0.8 >this.sharedData.maxWidthPopup ? this.sharedData.maxWidthPopup : window.innerWidth * 0.8);
    }
  }

  public getControlWidthModalPopUp(){
    let controlWidth:number=0;
    if(!this.isMobileMode())
    {
      controlWidth=window.innerWidth * 0.8 > this.sharedData.maxWidthPopup ? this.sharedData.maxWidthPopup : window.innerWidth * 0.8;
      controlWidth=controlWidth-this.borderPopUps;
    }else{
      controlWidth=window.innerWidth;
    }
    return Math.round(controlWidth);
  }

  public getTitlePopUpHeigth(){
    if(this.isIPhoneXPortraitOrientation())
    {
      return this.titlePopUpHeigthIphoneXPortraitOrientation;
    }else{
      if(this.isMobileMode())
      {
        return this.titlePopUpHeightMobile;
      }else{
        return this.titlePopUpHeight;
      }
    }
  }

  public getParentFilter(filter){
    let parentFilter:any={
      GridFilter_BusinessObject_GUID:filter['GridFilter_BusinessObject_GUID'],
      GridFilter_ListboardBrowse_GUID: filter['GridFilter_ListboardBrowse_GUID'],
      GridFilter_ID:filter['GridFilter_ID'],
      GridFilter_Caption:filter['GridFilter_Caption'],
      GridFilter_Type:filter['GridFilter_Type'],
      GridFilter_DataType:filter['GridFilter_DataType'],
      GridFilter_ORKey:filter['GridFilter_ORKey'],
      GridFilter_SortKey:filter['GridFilter_SortKey']
    }
    return parentFilter;
  }

  public hideTooltipFroalaButton(){
    this.sharedData.currentTooltip={};
  }

  public hideTooltip(event?:any){
    if(event)
      event.target.id="";
      this.sharedData.currentTooltip={};
  }

  public showTooltipFilter(event:any,filter:any){
      let inputText:string="";
      if(filter['parentFilter'])
      {
        if(filter['GridFilter_Type']==2)
        {
          inputText=filter['parentFilter']['GridFilter_Caption']+":"+filter['GridFilter_Caption'];
        }
        if(filter['GridFilter_Type']==4)
        {
          if(filter['showPrefixCaption'])
          {
            if(filter['GridFilter_PrefixCaption'] && filter['GridFilter_PrefixCaption']!='')
            {
              inputText=filter['GridFilter_PrefixCaption']+" : "+filter['GridFilter_Caption'];
            }
            if(!filter['GridFilter_PrefixCaption'] || (filter['GridFilter_PrefixCaption'] && filter['GridFilter_PrefixCaption']==''))
            {
              inputText=filter['GridFilter_Caption']
            }
          }else{
            inputText=filter['GridFilter_Caption']
          }
        }
      }else{  
        if(filter['GridFilter_Tooltip'])
        {
          inputText=filter['GridFilter_Tooltip'];
        }else{
          inputText=filter['GridFilter_Caption'];
        }
      }
      this.showToolTip(event,inputText);
  }

  public showTooltipSelectedToolbarOptions(event:any,selectedToolbarOptionList:any){
    if(selectedToolbarOptionList && selectedToolbarOptionList.length>0)
    {
      let stringHtml="";
      selectedToolbarOptionList.forEach(detail => {
        if(detail && detail.ActionType)
        {
          let actionType:string="";
          if(detail.ActionType=="View")
          {
            actionType=this.fdp.transform('columnsBy', 'GetTranslationCaption');
          }
          if(detail.ActionType=="GroupBy")
          {
            actionType=this.fdp.transform('groupBy', 'GetTranslationCaption');
          }
          if(detail.ActionType=="OrderBy")
          {
            actionType=this.fdp.transform('sortBy', 'GetTranslationCaption');
          }
          stringHtml=stringHtml+"<div><span>"+actionType+" : "+detail.Description+"</span><br></div>";
        }
      });
      this.showToolTip(event,stringHtml,true);
    }
  }

  public showToolTipCard(event:any,card:any) {
    if(card && card.titleTruncated){
      this.showToolTip(event,card.Title);
    }
  }

  public showToolTipCellCard(event:any,card:any) {
    if(!this.isMobile())
    {
      if(card && card.TitleFixed && card.TitleFixed!=""){  
        let text="";
        if(card.Title!=undefined && card.Title!='')
        {
          text=card.Title;
        }else if(card.TitleRight!=undefined && card.TitleRight!='')
        {
          text=card.TitleRight;
        }
        this.showToolTip(event,text);
      }
    } 
  }

  public showToolTip(event:any,input:any,fromCustomHtml?:boolean,notCaptionKey?:boolean) {
      if(event && !this.isMobile() && input && input != ''){
        this.executeShowToolTip(event,input,fromCustomHtml,notCaptionKey);
      }
  }

  public executeShowToolTip(event:any,input:any,fromCustomHtml?:boolean,notCaptionKey?:boolean){
      this.sharedData.currentTooltip={};
      if(!event.target.id){
        let generatedId:string="id"+Math.round(Math.random() * 10000000);
        event.target.id=generatedId;
      }
      const rect =event.target.getBoundingClientRect();
      this.sharedData.currentTooltip.elementId=event.target.id;
      let x = event.x- rect.left;
      let y = event.y - rect.top + 20;
      this.sharedData.currentTooltip.offset=x+" "+y;
    if(fromCustomHtml)
    {
      this.sharedData.currentTooltip.innerHtml=input;
    }else{
      if(!notCaptionKey)
      {
        input=this.fdp.transform(input, 'GetTranslationCaption');
      }
      this.sharedData.currentTooltip.innerHtml=this.getHtmlTooltip(input);
    }
    this.sharedData.currentTooltip.showTooltip=true;  
  }

  public getHtmlTooltip(input:any){
    return "<div style='max-width:900px;white-space: pre-line;'><span>"+input+"</span></div>";
  }

  public orderFilterInToolbar(listSelectedFilters:any):any{
    if(listSelectedFilters && listSelectedFilters.length>0)
    {
      listSelectedFilters.sort(this.sort_by('GridFilter_ID', false, parseInt));
      listSelectedFilters.sort(this.sort_by('GridFilter_SortKey', false, parseInt));
      listSelectedFilters.sort(function(a,b){
        if(a['GridFilter_Type']!=4 && b['GridFilter_Type']==4)
        {
            return 1;
        }else{
          if(a['GridFilter_Type']==4 && b['GridFilter_Type']!=4)
          {
            return -1;
          }else{
            return 0;
          }
        }
      });
      listSelectedFilters.sort(function(a,b){
        if(a['GridFilter_ORKey']!="" && b['GridFilter_ORKey']=="")
        {
            return 1;
        }else{
          if(a['GridFilter_ORKey']=="" && b['GridFilter_ORKey']!="")
          {
            return -1;
          }else{
            return 0;
          }
        }
      });
    }
    return listSelectedFilters
  }

  public enableAddButtonBrowse(filter:any,listSelectedFilters:any[]){
    let result:boolean=false;
    let listFilters=listSelectedFilters.filter(item => item['GridFilter_ID']==filter['GridFilter_ID']);
    if(listFilters && listFilters.length>0)
    {
      if(listFilters.indexOf(filter)==listFilters.length-1)
      {
        result=true;
      }
    }
    return result;
  }

  public enableAndFilter(filter:any,listSelectedFilters:any):boolean{
      let result:boolean=false;
      if(filter['GridFilter_ORKey']!='')
      {
        let listFilters=listSelectedFilters.filter(item => item['GridFilter_ORKey']==filter['GridFilter_ORKey']);
        if(listFilters && listFilters.length>0)
        {
          if(listFilters.indexOf(filter)==listFilters.length-1)
          {
            result=true;
          }
        }
      }else{
        if(filter['GridFilter_Type']==2 || filter['GridFilter_Type']==4)
        {
          let listFilters=listSelectedFilters.filter(item => item['GridFilter_ID']==filter['GridFilter_ID']);
          if(listFilters && listFilters.length>0)
          {
            if(listFilters.indexOf(filter)==listFilters.length-1)
            {
              result=true;
            }
          }
        }else{
          result=true;
        }
      }
      return result;
  }

  public addEditItemPopUp(popupdata:any){
    this.hideTooltip();
    popupdata.popUpId="popUpId-"+uuid();
    this.sharedData.listEditItemPopUp.push(popupdata);
    this.showHiddenMessage();
  }

  public addCreateDocumentWizardPopUp(popupdata:any){
    this.hideTooltip();
    popupdata.popUpId="popUpId-"+uuid();
    this.sharedData.listCreateDocumentWizardPopUp.push(popupdata);
  }

  public addCardListModalPopUp(popupdata:any){
    this.hideTooltip();
    popupdata.popUpId="popUpId-"+uuid();
    this.sharedData.listCardListModalPopUp.push(popupdata);
  }

  public addFilterListPopUp(popupdata:any){
    this.hideTooltip();
    popupdata.popUpId="popUpId-"+uuid();
    this.sharedData.listFilterListPopUp.push(popupdata);
  }

  public getCardListModalPopUpByParentPageAndPopUpId(popupParent:any){
    let cardListModalPopUp=this.sharedData.listCardListModalPopUp.find(item => item.popupParent == popupParent);
    return cardListModalPopUp;
  }
  
  public addCardDetailPopUp(popupdata:any){
    this.hideTooltip();
    this.hideAllContextMenu();
    popupdata.popUpId="popUpId-"+uuid();
    this.sharedData.listCardDetailPopUp.push(popupdata);
  }

  public getUserPreferencesBrowse(browseGuid: string):any {
    let dataUserPreference={};
    let userSetting:any=undefined;
    if(this.sharedData.userSettings.length>0)
    {
      userSetting=this.sharedData.userSettings.find(us => us['browse_Guid'] == browseGuid);
    }
    if(userSetting && userSetting['data'])
    {
      dataUserPreference=JSON.parse(userSetting['data']);
    }
    return dataUserPreference;
  }

  public getUserPreferences(viewId: number, pluginId?: number,cardId?:number):any {
    let dataUserPreference={};
    let userSetting:any=undefined;
    if(this.sharedData.userSettings.length>0)
    {
      if(viewId && pluginId)
      {
        if(cardId)
        {
          userSetting=this.sharedData.userSettings.find(us => us['view_id'] == viewId &&  us['plugin_id'] == pluginId && us['card_id'] == cardId);
        }else{
          userSetting=this.sharedData.userSettings.find(us => us['view_id'] == viewId &&  us['plugin_id'] == pluginId && us['card_id'] == undefined);
        }
      }else{
        if(viewId)
        {
          userSetting=this.sharedData.userSettings.find(us => us['view_id'] == viewId && us['card_id'] == undefined);
        }
      }
    }
    if(userSetting && userSetting['data'])
    {
      dataUserPreference=JSON.parse(userSetting['data']);
    }
    return dataUserPreference;
  }

  public showNotDeletePermissionMessage(plugin:any){
    let html:string='<div style="display:flex;align-items:center"><img style="width: 35px;margin-right: 10px;" src="assets/icon/nu-circle-remove.png">'+this.fdp.transform('No Delete Permission', 'GetTranslationCaption')+'<div>';
    let options = {
      title: plugin.CurrentCaption,
      messageHtml: html,
      buttons: [
        {
          text: 'Ok',
          onClick: (() => 'ok')
        }
      ]
    }
    let dialog = custom(options);
    dialog.show().done(res => {
      switch (res) {
        case 'ok':
          break;
        default:
          break;
      }
    });
  }

  public verifyAllowDeleteCard(card:any,listObjects:any[]):boolean{
    let enableDeleteCard:boolean=false;
    if(listObjects && listObjects.length>0)
    {
      let listObjectsForDelete=listObjects.filter(obj => obj.DeletePermission==true);
      let index=listObjectsForDelete.findIndex(obj => obj.ID==card.BOID);
      if(index!=-1)
        enableDeleteCard=true;
    }
    return enableDeleteCard;
  }

  public verifyAllowDeleteListCard(cardList:any,listObjects:any[]){
    let allowedCardsListDelete: any[] = [];
    let notAllowedCardListDelete:any[]=[];
    if(cardList && cardList.length>0)
    {
      cardList.forEach(card => {
        if (this.verifyAllowDeleteCard(card, listObjects)) {
          allowedCardsListDelete.push(card);
        }else{
          notAllowedCardListDelete.push(card);
        }
      });
    }
    let result = {
      notAllowedCardListDelete:notAllowedCardListDelete,
      allowedCardsListDelete:allowedCardsListDelete
    }
    return result;
  }

  public verifyAllowEditCard(card:any,listObjects:any[]):boolean{
    let enableEditCard:boolean=false;
    if(listObjects && listObjects.length>0)
    {
      let listObjectsForRead=listObjects.filter(obj => obj.ReadPermission==true);
      let index=listObjectsForRead.findIndex(obj => obj.ID==card.BOID);
      if(index!=-1)
        enableEditCard=true;
    }
    return enableEditCard;
  }


  public getListObjectsToCreate(listObjects:any[]):any[]{
    let listObjectsToCreate:any[]=[];
    if(listObjects)
    {
      listObjectsToCreate=listObjects.filter(obj => obj.CreatePermission==true);
    }
    return listObjectsToCreate;
  }

  async configureDeploy() {
    let updateMethod: any = 'none';
    let appId = '51a74936';
    this.channel = 'Production';
    if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.0.1.8')==0) {//DEV
      const config = {
        appId: appId,
        channel: this.channel,
        updateMethod: updateMethod
      }
      await Pro.deploy.configure(config);
    } else {
      if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.1.0.47') <= 0) {
        this.channel = 'Channel51';
        const config = {
          appId: appId,
          channel: this.channel,
          updateMethod: updateMethod
        }
        await Pro.deploy.configure(config);
      } else {
        if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.1.0.66') <= 0) {
          this.channel = 'Channel66';
          const config = {
            appId: appId,
            channel: this.channel,
            updateMethod: updateMethod
          }
          await Pro.deploy.configure(config);
        } else {
          if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.1.0.72') <= 0) {// PREV VERSION
            this.channel = 'Channel70';
            const config = {
              appId: appId,
              channel: this.channel,
              updateMethod: updateMethod
            }
            await Pro.deploy.configure(config);
          } else {
            if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.2.0.11') <= 0) {// PREV VERSION
              this.channel = 'Channel90';
              const config = {
                appId: appId,
                channel: this.channel,
                updateMethod: updateMethod
              }
              await Pro.deploy.configure(config);
            } else {
              if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.2.0.19') <= 0) {// PREV VERSION
                this.channel = 'Channel93';
                const config = {
                  appId: appId,
                  channel: this.channel,
                  updateMethod: updateMethod
                }
                await Pro.deploy.configure(config);
              } else {
                if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.2.0.44') <= 0) {// PREV VERSION
                  this.channel = 'Channel98';
                  const config = {
                    appId: appId,
                    channel: this.channel,
                    updateMethod: updateMethod
                  }
                  await Pro.deploy.configure(config);
                } else {
                  if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.2.0.51') <= 0) {// PREV VERSION
                    this.channel = 'Channel99';
                    const config = {
                      appId: appId,
                      channel: this.channel,
                      updateMethod: updateMethod
                    }
                    await Pro.deploy.configure(config);
                  } else {
                    if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.2.0.62') <= 0) {// PREV VERSION
                      this.channel = 'Channel100';
                      const config = {
                        appId: appId,
                        channel: this.channel,
                        updateMethod: updateMethod
                      }
                      await Pro.deploy.configure(config);
                    } else {
                      if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.2.0.82') <= 0) {// PREV VERSION
                        this.channel = 'Channel103';
                        const config = {
                          appId: appId,
                          channel: this.channel,
                          updateMethod: updateMethod
                        }
                        await Pro.deploy.configure(config);
                      } else {
                        if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.2.0.98') <= 0) {// PREV VERSION
                          this.channel = 'Channel106';
                          const config = {
                            appId: appId,
                            channel: this.channel,
                            updateMethod: updateMethod
                          }
                          await Pro.deploy.configure(config);
                        } else {
                          if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.2.0.101') <= 0) {// PREV VERSION
                            this.channel = 'Channel107';
                            const config = {
                              appId: appId,
                              channel: this.channel,
                              updateMethod: updateMethod
                            }
                            await Pro.deploy.configure(config);
                          } else {
                            if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.2.0.104') <= 0) {// PREV VERSION
                              this.channel = 'Channel107.2';
                              const config = {
                                appId: appId,
                                channel: this.channel,
                                updateMethod: updateMethod
                              }
                              await Pro.deploy.configure(config);
                            }else{
                              if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.2.0.106') <= 0) {// PREV VERSION
                                this.channel = 'Channel108';
                                const config = {
                                  appId: appId,
                                  channel: this.channel,
                                  updateMethod: updateMethod
                                }
                                await Pro.deploy.configure(config);
                              }else{
                                if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.2.0.109') <= 0) {// PREV VERSION
                                  this.channel = 'Channel109.1';
                                  const config = {
                                    appId: appId,
                                    channel: this.channel,
                                    updateMethod: updateMethod
                                  }
                                  await Pro.deploy.configure(config);
                                }else{
                                  if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.2.0.123') <= 0) {// PREV VERSION
                                    this.channel = 'Channel110';
                                    const config = {
                                      appId: appId,
                                      channel: this.channel,
                                      updateMethod: updateMethod
                                    }
                                    await Pro.deploy.configure(config);
                                  }else{
                                    if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.2.0.160') <= 0) {// PREV VERSION
                                      this.channel = 'Channel111.1';
                                      const config = {
                                        appId: appId,
                                        channel: this.channel,
                                        updateMethod: updateMethod
                                      }
                                      await Pro.deploy.configure(config);
                                    }else{
                                      if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.2.0.175') <= 0) {// PREV VERSION
                                        this.channel = 'Channel112';
                                        const config = {
                                          appId: appId,
                                          channel: this.channel,
                                          updateMethod: updateMethod
                                        }
                                        await Pro.deploy.configure(config);
                                      }else{
                                        if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.2.0.179') <= 0) {// PREV VERSION
                                          this.channel = 'Channel113.1';
                                          const config = {
                                            appId: appId,
                                            channel: this.channel,
                                            updateMethod: updateMethod
                                          }
                                          await Pro.deploy.configure(config);
                                        }else{
                                          if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.2.0.186') <= 0) {// PREV VERSION
                                            this.channel = 'Channel114.2';
                                            const config = {
                                              appId: appId,
                                              channel: this.channel,
                                              updateMethod: updateMethod
                                            }
                                            await Pro.deploy.configure(config);
                                          }else{
                                            if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.2.0.203') <= 0) {// PREV VERSION
                                              this.channel = 'Channel115';
                                              const config = {
                                                appId: appId,
                                                channel: this.channel,
                                                updateMethod: updateMethod
                                              }
                                              await Pro.deploy.configure(config);
                                            }else{
                                              const config = {
                                                appId: appId,
                                                channel: this.channel,
                                                updateMethod: updateMethod
                                              }
                                              await Pro.deploy.configure(config);
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          } 
                        } 
                      } 
                    } 
                  } 
                } 
              } 
            } 
          } 
        } 
      }
    }
    this.sharedData.channel=this.channel;
}

async checkAndDownloadUpdate(){
  const update = await Pro.deploy.checkForUpdate()
  if(update && update.available && update.build)
  {
    console.log("update:::::"+JSON.stringify(update));
    console.log("currentBuild:::"+update.build);
    if(this.sharedData.channel=='Production' || this.sharedData.channel=='Master')
    {
      let currentBuild=parseInt(update.build);
      if(currentBuild > this.lastBuild)
      {
        this.prepareAndDownloadUpdate();
      }
    }else{
      this.prepareAndDownloadUpdate();
    }
  }
}

private prepareAndDownloadUpdate(){
  this.sharedData.loadingText=this.fdp.transform('Loading', 'GetTranslationCaption');
        this.sharedData.pleaseWaitText=this.fdp.transform('PleaseWait', 'GetTranslationCaption');
        this.sharedData.updatingAppText=this.fdp.transform('UpdatingApp', 'GetTranslationCaption');
        setTimeout(() => {
          this.sharedData.displayUpdatingWindow=true;
          this.executeDownloadUpdate();
        }, 500);
}

async executeDownloadUpdate(){
  await Pro.deploy.downloadUpdate((progress) => {
      this.sharedData.progressDownloading=progress+"%";
      //this.showMessage('success',"DownloadingProgress:::"+progress+"%",2000000000)
    }).then(success=>{
    this.executeExtractUpdate();
  }).catch(error=>{ 
    this.showMessage('error',error);
    this.sharedData.displayUpdatingWindow=false;
    this.sharedData.progressDownloading='';
    console.error(error);
 });
}

async reloadApp(){
  await Pro.deploy.reloadApp();
}

async executeExtractUpdate(){
  this.showMessage('success',"executeExtractUpdate");
  await Pro.deploy.extractUpdate((progress) => {}).then(success=>{
    this.reloadApp();
  }).catch(error=>{ 
    this.showMessage('error',error);
    console.error(error);
    this.executeExtractUpdate();
 });
}

public isTabletMode():boolean{
  if(window.innerWidth > this.maxWidthTabletMode)
  {
    return false;
  }else if(this.isMobileMode()){
    return false;
  }
  return true;
}


  public isMobileMode():boolean{
    if(window.innerWidth > this.maxWidthMobileMode)
    {
      return false;
    }
    return true;
  }

  public isChromeBrowser():boolean {
    const agent = window.navigator.userAgent.toLowerCase();
    if (agent.indexOf('chrome') > -1 && !!(<any>window).chrome) {
      return true;
    } 
    return false;
  }

  public disableDeleteSelectedItem(globalCount:number,subPlugin:any,plugin:any){
    if(globalCount>0 && subPlugin && (subPlugin.GUID=='4b5eeca4-79b8-450d-aa90-0876454d984d') && plugin.GUID!='8c0cb8f4-4c7d-4a9f-b4f1-7a6178694f32')
    {
      return true;
    }
    return false;
  }

  public unblockAllScreen(){
    this.events.publish('HomePage:executeBlockOrUnblockScreenContent', {blockScreen:false});
    this.events.publish('MainPage:executeBlockOrUnblockScreenContent', {blockScreen:false});
  }

  public blockAllScreen(){
    this.events.publish('HomePage:executeBlockOrUnblockScreenContent', {blockScreen:true});
    this.events.publish('MainPage:executeBlockOrUnblockScreenContent', {blockScreen:true});
  }

  public executeBlockOrUnblockScreen(blockScreen:boolean,globalCount:number){
    let jsonParams:any={
      blockScreen:blockScreen,
      disableListboard:this.sharedData.disableListboard
    }
    if(globalCount==0)
    {
       this.events.publish('HomePage:executeBlockOrUnblockScreen',{jsonParams:jsonParams});
    }else{
      this.events.publish('CardDetailPage:executeBlockOrUnblockScreen'+globalCount,{jsonParams:jsonParams});
    }
  }

  public dragElement(popUpId, elmnt) {
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    let titlePopUp=document.getElementById("title"+popUpId);
    if(titlePopUp)
      titlePopUp.onmousedown = dragMouseDown;

    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      let top = elmnt.offsetTop - pos2;
      if(top<0)
      {
        top=0;
      }
      let left=elmnt.offsetLeft - pos1;
      if(left<0)
      {
        left=0;
      }
      if(left+elmnt.offsetWidth<=window.innerWidth)
        elmnt.style.left = left + "px";
      if(top + elmnt.offsetHeight<=window.innerHeight)  
        elmnt.style.top = top + "px"; 
    }

    function closeDragElement() {
      /* stop moving when mouse button is released:*/
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }

  public isIPhoneXPortraitOrientation(): boolean {
    if (this.testIphoneX) {
      if (!this.screenOrientation.type.startsWith('landscape')) {
        return true;
      }
    } else {
      if (this.isMobile()) {
        if (this.device.model.includes('iPhone10,3') || this.device.model.includes('iPhone10,6') || this.device.model.includes('iPhone11') || this.device.model.includes('iPhone12') || this.device.model.includes('iPhone13')) {
          if (!this.screenOrientation.type.startsWith('landscape')) {
            return true;
          }
        }
      }
      return false;
    }
  }

  public getShowScrollbar() {
    if ((<any>window).cordova != undefined) {
      return 'never';
    } else {
      return 'always';
    }
  }

  public getStyleDisplayScrollView() {
        return 'block';
  }

  public isMobile(): boolean {
    return (<any>window).cordova != undefined;
  }

  public setResources(): void {
    
    let normalizeCss = document.createElement('link');
    normalizeCss.setAttribute('href',this.sharedData.URLNormalizeCSS );
    normalizeCss.setAttribute('rel', 'stylesheet');
    document.getElementsByTagName('head')[0].appendChild(normalizeCss);

    let previewCss = document.createElement('link');
    previewCss.setAttribute('href', this.sharedData.URLPreviewCSS);
    previewCss.setAttribute('rel', 'stylesheet');
    document.getElementsByTagName('head')[0].appendChild(previewCss);

    let highlightCss = document.createElement('link');
    highlightCss.setAttribute('href', this.sharedData.URLHighlightCSS);
    highlightCss.setAttribute('rel', 'stylesheet');
    document.getElementsByTagName('head')[0].appendChild(highlightCss);

    let highlightJs = document.createElement('script');
    highlightJs.setAttribute('src', this.sharedData.URLHighlightJS);
    document.getElementsByTagName('head')[0].appendChild(highlightJs);

    let previewJs = document.createElement('script');
    previewJs.setAttribute('src', this.sharedData.URLPreviewJS);
    document.getElementsByTagName('head')[0].appendChild(previewJs);
  }

  public orderPlugins(plugins: any[]): any[] {
    let groups = [];
    for (let i = 0; i < plugins.length; i++) {

      if (plugins[i]['PluginSortKey'].indexOf("!") != -1)
        plugins[i]['ShowPipe'] = true

      plugins[i]['PluginSortKey'] = plugins[i]['PluginSortKey'].replace('!', '');
      let plugin = plugins[i];

      if (plugin['CurrentCaption'] && plugin['CurrentCaption'].indexOf('|') != -1) {
        let caption = plugin['CurrentCaption'].split('|');
        plugin['CurrentCaption'] = caption[0];
        plugin['Tooltip'] = caption[1];
      }

      let parts = plugin['PluginSortKey'].split('-');
      if (parts[0] == 'Plugins') {
        plugin['Group'] = 9999;
      } else {
        plugin['Group'] = parts[0] / 1;
      }
      plugin['Order'] = parts[1] / 1;

      if (groups.indexOf(plugin['Group']) == -1) {
        groups.push(plugin['Group']);
      }
    }
    let result = [];
    for (let i = 0; i < groups.length; i++) {
      let tmp = [];
      for (let j = 0; j < plugins.length; j++) {
        if (plugins[j]['Group'] == groups[i]) {
          tmp.push(plugins[j]);
        }
      }
      tmp.sort(this.sort_by('Order', false, parseInt));
      let caption = tmp[0]['CurrentCaption'];
      let tooltip = tmp[0]['Tooltip'];
      if (groups[i] == 9999) {
        caption = 'Plugins';
      }
      if (caption != '') {
        result.push({ Group: groups[i], Caption: caption, Tooltip: tooltip, Items: tmp, CurrentIdx: 0 });
      }
    }
    result.sort(this.sort_by('Group', false, parseInt))
    if(result.length>0 && result[0].Items && result[0].Items.length>0 && result[0].Items[0].ShowPipe)
    {
      result[0].Items[0].ShowPipe=false;
    }
    return result;
  }

  public showHiddenMessage() {
    let options = {
      message: ''
    }
    notify(options, "warning", 2000);
  }

  public showMessage(type: string, message?: string, displaytime?: number, width?: number,noTruncated?:boolean) {
    let param=window.innerWidth/message.length;
    let factor=7.3;
    let msgSize=Math.round(window.innerWidth/factor);
    if(!noTruncated && param<factor && type!="Error")
    {
      message=message.substr(0, msgSize) + '...';
      width=window.innerWidth;
    }
    let options:any = {
      position: { at: 'bottom', offset: '0 -50' },
      message: message
    }
    if(width)
    {
      options.width=width;
    }
    if (displaytime) {
      notify(options, type, displaytime);
    } else {
      notify(options, type, 2000);
    }
  }

  public sort_by(field, reverse, primer) {
    let key = primer ? (x) => primer(x[field]) : (x) => x[field];
    reverse = !reverse ? 1 : -1;
    return (a, b) => { return a = key(a), b = key(b), reverse * (+(a > b) - +(b > a)) }
  }

  public groupCardList(cardList: any[], option: any, columnGroup: string): any[] {
    let groups: any[] = [];
    if (cardList != undefined) {
      cardList.forEach(card => {
        if (card[columnGroup]) {
          let cardGroupData: string = card[columnGroup];
          let groupName = '';
          let groupId = '';
          if (cardGroupData.indexOf('#sort#') != -1) {
            let temp = cardGroupData.split('#sort#');
            groupId = temp[0];
            groupName = temp[1].trim();
          } else {
            let dateReg = /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/g;
            if (cardGroupData.match(dateReg)) {
              let tmp = this.getGroupNameFromDate(card, columnGroup).split('#');
              groupId = tmp[0];
              groupName = tmp[1];
            } else {
              groupId = cardGroupData;
              groupName = cardGroupData;
            }
          }
          let group = groups.find(g => g['id'] == groupId);
          if (group == undefined) {
            groups.push(
              {
                id: groupId,
                name: groupName,
                items: [card],
                show: true,
                sumTotal1: card.Sum?card.Sum:0,
                sumTotal2: card.Sum2?card.Sum2:0,
                sumTotal3: card.Sum3?card.Sum3:0
              }
            )
          } else {
            group.sumTotal1 = group.sumTotal1 + card.Sum;
            group.sumTotal2 = group.sumTotal2 + card.Sum2;
            group.sumTotal3 = group.sumTotal3 + card.Sum3;
            group.items.push(card);
          }
        } else {
          let groupId = 'novalue';
          let groupName = 'ListBoardNoGroupText';
          let group = groups.find(g => g['id'] == groupId);
          if (group == undefined) {
            groups.push(
              {
                id: groupId,
                name: groupName,
                items: [card],
                show: true,
                sumTotal1: card.Sum?card.Sum:0,
                sumTotal2: card.Sum2?card.Sum2:0,
                sumTotal3: card.Sum3?card.Sum3:0
              }
            )
          } else {
            group.sumTotal1 = group.sumTotal1 + card.Sum;
            group.sumTotal2 = group.sumTotal2 + card.Sum2;
            group.sumTotal3 = group.sumTotal3 + card.Sum3;
            group.items.push(card);
          }
        }
      });
      if (option['Type'] == 'ASC') {
        groups.sort(this.sort_by('id', false, (obj) => {
          if (obj) {
            if(isNaN(obj))
            {
              try{
                if (obj.match(/[A-Za-z]/i)) {
                  return obj.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                } else {
                  return new Date(this.fdtp.transform(obj, ''));
                }
              }catch(e)
              {
                if(isNaN(parseInt(obj)))
                {
                  if (obj.match(/[A-Za-z]/i)) {
                    return obj.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                  } else {
                    return new Date(this.fdtp.transform(obj, ''));
                  }
                }else{
                  return parseInt(obj)/1;
                }
              }
            }else{
              return parseInt(obj)/1;
            }
          }
        }));
      } else {
        if (option['Type'] == 'DES') {
          groups.sort(this.sort_by('id', true, (obj) => {
            if (obj) {
              if(isNaN(obj))
              {
                try{
                  if (obj.match(/[A-Za-z]/i)) {
                    return obj.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                  } else {
                    return new Date(this.fdtp.transform(obj, ''));
                  }
                }catch(e)
                {
                  if(isNaN(parseInt(obj)))
                  {
                    if (obj.match(/[A-Za-z]/i)) {
                      return obj.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                    } else {
                      return new Date(this.fdtp.transform(obj, ''));
                    }
                  }else{
                    return parseInt(obj)/1;
                  }
                }
              }else{
                return parseInt(obj)/1;
              }
            }
          }));
        }
      }
    }
    return groups;
  }

  public sortGroupCards(groups: any[], option: any): any[] {
    groups.forEach(group => {
      if (option['Type'] == 'ASC') {
        group.items.sort(this.sort_by('OrderData', false, (obj) => {
          if (obj) {
            if(isNaN(obj))
            {
              try{
                if (obj.match(/[A-Za-z]/i)) {
                  return obj.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                } else {
                  return new Date(this.fdtp.transform(obj, ''));
                }
              }catch(e)
              {
                if(isNaN(parseInt(obj)))
                {
                  if (obj.match(/[A-Za-z]/i)) {
                    return obj.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                  } else {
                    return new Date(this.fdtp.transform(obj, ''));
                  }
                }else{
                  return parseInt(obj)/1;
                }
              }
            }else{
              return parseInt(obj)/1;
            }
          }
        }));
      } else {
        group.items.sort(this.sort_by('OrderData', true, (obj) => {
          if (obj) {
            if(isNaN(obj))
            {
              try{
                if (obj.match(/[A-Za-z]/i)) {
                  return obj.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                } else {
                  return new Date(this.fdtp.transform(obj, ''));
                }
              }catch(e)
              {
                if(isNaN(parseInt(obj)))
                {
                  if (obj.match(/[A-Za-z]/i)) {
                    return obj.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                  } else {
                    return new Date(this.fdtp.transform(obj, ''));
                  }
                }else{
                  return parseInt(obj)/1;
                }
              }
            }else{
              return parseInt(obj)/1;
            }
          }
        }));
      }
    });
    return groups;
  }

  public getGroupNameFromDate(card: any, columnGroup: string,order?:string): string {

    let datePipe = new DatePipe('en-US');
    let itemDate = new Date(this.fdtp.transform(card[columnGroup], ''));
    let currentDate = new Date(this.fdtp.transform(new Date(), ''));
    let cdMonth: number = +datePipe.transform(currentDate, 'M');
    let cdYear: number = +datePipe.transform(currentDate, 'y');
    let cdWeek: number = moment(currentDate).subtract(1, 'days').week();
    let itWeek: number = moment(itemDate).subtract(1, 'days').week();
    let itMonth: number = +datePipe.transform(itemDate, 'M');
    let itYear: number = +datePipe.transform(itemDate, 'y');
    let itMonthDesc: string = datePipe.transform(itemDate, 'MMMM');
    let timeDiff = currentDate.getTime() - itemDate.getTime();
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (diffDays == 0) {
      return '0#Today';
    } else {
      if (diffDays > 0) { // is positive if current date is greather than item date
        if (diffDays == 1) {
          return '-1#Yesterday';
        } else {
          if (diffDays > 1 && itWeek == cdWeek && itYear == cdYear) {
            return '-2#ThisWeek';
          } else {
            if (itWeek == cdWeek - 1 && itYear == cdYear) {
              return '-3#PreviousWeek';
            } else {
              if (cdMonth == itMonth && cdYear == itYear) {
                return '-4#CurrentMonth';
              } else {
                if (cdYear == itYear) {
                  let itMonthTmp=5000-itMonth;
                  return '-5' + itMonthTmp + '#' + itMonthDesc;
                } else {
                  let itYearTmp=5000-itYear;
                  return '-6' + itYearTmp + '#' + itYear;
                }
              }
            }
          }
        }
      } else {
        if (diffDays == -1) {
          return '1#Tomorrow';
        } else {
          if (diffDays < -1 && itWeek == cdWeek && itYear == cdYear) {
            return '2#ThisWeekUp';
          } else {
            if (itWeek == cdWeek + 1 && itYear == cdYear) {
              return '3#NextWeek';
            } else {
              if (cdMonth == itMonth && cdYear == itYear) {
                return '4#CurrentMonthUp';
              } else {
                if (cdYear == itYear) {
                  return '5' + itMonth + '#' + itMonthDesc;
                } else {
                  return '6' + itYear + '#' + itYear;
                }
              }
            }
          }
        }
      }
    }
  }

  public getParamFilters(listSelectedFilters:any){
    let listFilters =[];
    if(listSelectedFilters && listSelectedFilters.length>0)
    {
      listSelectedFilters.forEach(filter => {
        let json={};
        json['GridFilter_ID']=filter['GridFilter_ID'];
        json['GridFilter_Caption']=hiBase64.encode(filter['GridFilter_Caption']);
        json['GridFilter_Tooltip']=filter['GridFilter_Tooltip'];
        json['GridFilter_PictureName']=filter['GridFilter_PictureName'];
        json['GridFilter_Selected']=filter['GridFilter_Selected'];
        json['GridFilter_Values']=filter['GridFilter_Values'];
        json['GridFilter_Type']=filter['GridFilter_Type'];
        json['GridFilter_ORKey']=filter['GridFilter_ORKey'];
        json['GridFilter_SortKey']=filter['GridFilter_SortKey'];
        json['ID']=filter['ID'];
        json['parentFilter']=filter['parentFilter'];
        json['showPrefixCaption']=filter['showPrefixCaption'];
        json['show']=filter['show'];
        json['GridFilter_IsNegative']=filter['GridFilter_IsNegative'];
        listFilters.push(JSON.stringify(json));
      });  
    }
    return listFilters;
  }

  public getListFiltersDecodeBase64(listSelectedFilters:any){
    try{
      if(listSelectedFilters && listSelectedFilters.length>0)
      {
        listSelectedFilters.forEach(filter => {
          try{
            filter['GridFilter_Caption']=hiBase64.decode(filter['GridFilter_Caption']);
          }catch(error){
            console.log(error);
          }
        });
      }
    }catch(error){
      console.log(error);
    }
    return listSelectedFilters;
  }

  public getBrowseGuidFilters(listFilters: any[]): any[] {
    let browseGuidFilters = [];
    if (listFilters != undefined && listFilters.length > 0) {
      listFilters.forEach(filter => {
        if (filter['GridFilter_Selected']) {
          if (filter['GridFilter_ListboardBrowse_GUID'] != '') {
            browseGuidFilters.push(filter['GridFilter_ListboardBrowse_GUID']);
          }
        }
      });
    }
    return browseGuidFilters;
  }

  public toogleGroup(group: any) {
    group.show = !group.show;
  }

  public getInputType(dataType: number, dataFormat: string): string {
    let inputType = 'text';

    switch (dataType) {
      case 1:
        inputType = 'text';
        break;

      case 2:
        inputType = 'number';
        break;

      case 3:
        inputType = 'number';
        break;

      case 4:
        inputType = 'checkbox';
        break;

      case 7:
        inputType = 'date';
        break;

      default:
        inputType = 'object';
        break;
    };


    if (inputType == 'date') {
      if (dataFormat == 'DateShort' || dataFormat == 'DateMedium') {
        inputType = 'datetime-local';
      } else {
        if (dataFormat == 'DateMediumTime' || dataFormat == 'DateShortTime') {
          inputType = 'time';
        }
      }
    }
    return inputType;
  }

  public sleep(ms: number): Promise<any> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public compareVersions(v1: string, v2: string, options?: any): number {
    if (v1 == undefined) {
      return -1;
    }

    let lexicographical: boolean = options && options.lexicographical;
    let zeroExtend: boolean = options && options.zeroExtend;
    let v1parts: string[] = v1.split('.');
    let v2parts: string[] = v2.split('.');


    if (!v1parts.every((x) => {
      return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
    }) || !v2parts.every((x) => {
      return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
    })) {
      return NaN;
    }

    if (zeroExtend) {
      while (v1parts.length < v2parts.length)
        v1parts.push("0");
      while (v2parts.length < v1parts.length)
        v2parts.push("0");
    }

    if (!lexicographical) {
      v1parts = v1parts.map(String);
      v2parts = v2parts.map(String);
    }

    for (let i = 0; i < v1parts.length; ++i) {
      if (v2parts.length == i) {
        return 1;
      }
      if (v1parts[i] == v2parts[i]) {
        continue;
      } else if (Number.parseInt(v1parts[i]) > Number.parseInt(v2parts[i])) {
        return 1;
      } else {
        return -1;
      }
    }

    if (v1parts.length != v2parts.length) {
      return -1;
    }
    return 0;
  };

  public getTextColor(color: number): any {
    let rr = 0;
    let gg = 0;
    let bb = 0;
    let hexnum = '#' + ('000000' + (color & 0xFFFFFF).toString(16)).slice(-6);
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexnum);
    if (result) {
      let r = parseInt(result[1], 16);
      let g = parseInt(result[2], 16);
      let b = parseInt(result[3], 16);
      let brightness = this.brightness(r, g, b);
      if (brightness < 123) {
        rr = 255;
        gg = 255;
        bb = 255;
      }
    }
    return "#" + this.componentToHex(rr) + this.componentToHex(gg) + this.componentToHex(bb);
  }

  private brightness(r: number, g: number, b: number): number {
    return (r * 299 + g * 587 + b * 114) / 1000
  }

  private componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

}
